<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-form ref="form">
          <v-card>
            <v-card-title class="msaBlue white--text">
              General Information
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row no-gutters> GENERAL </v-row>
                <v-row no-gutters class="pb-2">
                  <v-divider></v-divider>
                </v-row>
                <v-row align="center" dense>
                  <v-col cols="12">
                    <v-text-field
                      v-model="edited.firstName"
                      :rules="rules.firstName"
                      :error-messages="errors['firstName']"
                      label="First name (required)"
                      outlined
                      dense
                      counter
                      maxlength="200"
                      data-testid="firstName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="edited.lastName"
                      :rules="rules.lastName"
                      :error-messages="errors['lastName']"
                      label="Last name (required)"
                      dense
                      outlined
                      counter
                      maxlength="200"
                      data-testid="lastName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="edited.email"
                      :rules="rules.email"
                      :error-messages="errors['email']"
                      label="Email (required)"
                      dense
                      outlined
                      @keydown.space.prevent
                      counter
                      maxlength="200"
                      data-testid="email"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="edited.username"
                      label="Username"
                      outlined
                      dense
                      readonly
                      data-testid="username"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="edited.type"
                      label="Type"
                      outlined
                      dense
                      readonly
                      data-testid="username"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters> PASSWORD </v-row>
                <v-row no-gutters class="pb-2">
                  <v-divider></v-divider>
                </v-row>
                <v-row>
                  <v-col class="pb-0">
                    <v-text-field
                      v-model="edited.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      placeholder="******"
                      outlined
                      :rules="rules.password"
                      :error-messages="errors['password']"
                      dense
                      @click:append="showPassword = !showPassword"
                      data-test-id="password"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="msaBlue white--text"
                :disabled="disableSave"
                @click="save()"
                data-testid="saveButton"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'InternalAdminProfilePage',
  computed: {
    disableSave() {
      return JSON.stringify(this.original) === JSON.stringify(this.edited);
    },
  },
  data() {
    return {
      original: {},
      edited: {},
      rules: {
        email: [(v) => this.validateEmail(v)],
        firstName: [(v) => this.validateFirstName(v)],
        lastName: [(v) => this.validateLastName(v)],
        password: [(v) => this.validatePassword(v)],
      },
      errors: {
        firstName: [],
        lastName: [],
        email: [],
        password: [],
      },
      showPassword: false,
    };
  },
  methods: {
    getDetails() {
      const params = {
        loaderText: 'loading...',
      };
      const url = 'get-internal-admin-profile?format=json';
      this.$axios.post(url, params).then((response) => {
        this.original = response.data;
        this.edited = JSON.parse(JSON.stringify(this.original));
      });
    },
    validateEmail(email) {
      this.errors.email = [];
      return this.$helpers.validateEmail(email);
    },
    validateFirstName(name) {
      this.errors.firstName = [];
      return this.$helpers.required(name, 'First Name');
    },
    validateLastName(name) {
      this.errors.lastName = [];
      return this.$helpers.required(name, 'Last Name');
    },
    validatePassword(password) {
      this.errors.password = [];

      return password && password.trim()
        ? this.$helpers.validatePassword(password)
        : true;
    },
    save() {
      const params = {
        loaderText: 'saving...',
        payload: this.edited,
        snackText: 'Profile updated successfully.',
        disableAutoError: true,
      };
      const url = 'update-internal-admin-profile?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.errors = {
            firstName: [],
            lastName: [],
            email: [],
            password: [],
          };
          this.showPassword = false;
          this.edited.password = '';
          this.original = JSON.parse(JSON.stringify(this.edited));
          this.$store.commit('updateUser', {
            ...this.$store.getters.user,
            firstName: this.edited.firstName,
            lastName: this.edited.lastName,
          });
        })
        .catch((error) => {
          this.edited = { ...this.original };
          this.processErrors(error.response.data.errors);
        });
    },
    processErrors(errors) {
      for (const [key] of Object.entries(errors)) {
        this.errors[key].push(errors[key]);
      }
    },
  },
  mounted() {
    this.getDetails();
  },
  beforeMount() {
    //pinging the server just to fore a session
    this.$axios.post('public/ping?format=json', {});
  },
};
</script>
<style></style>
